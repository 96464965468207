import '../scss/style.scss';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Grid, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import 'swiper/css/effect-fade';
import Sticky from 'sticky-js';

document.addEventListener('DOMContentLoaded', function () {

    if (window.innerWidth > 960) {
        let sticky = new Sticky('.col-fixed');
    }

    if (document.querySelector('.open-mobile')) {
        const openMobileLink = document.querySelector('.open-mobile');
        const closeMobileLink = document.querySelector('.close-mobile');
        const navMain = document.getElementById('nav-main');

        openMobileLink.addEventListener('click', function (e) {
            e.preventDefault()
            navMain.classList.remove('lg:hidden');
            navMain.classList.add('lg:flex');
        });

        closeMobileLink.addEventListener('click', function (e) {
            e.preventDefault()
            navMain.classList.remove('lg:flex');
            navMain.classList.add('lg:hidden');
        });
    }

    if (window.innerWidth < 1100) {
        var openSubElements = document.querySelectorAll(".open-sub");

        openSubElements.forEach(function (openSubElement) {
            openSubElement.addEventListener("click", function () {
                var parentLi = openSubElement.closest("li");
                var navigationLi = parentLi.querySelector(".navigation-li");

                var allNavigationLi = document.querySelectorAll(".navigation-li");
                allNavigationLi.forEach(function (item) {
                    if (item !== navigationLi) {
                        item.classList.remove("lg:block", "lg:visible", "slideDown");
                        item.classList.add("lg:hidden", "slideUp");
                    }
                });

                navigationLi.classList.toggle("lg:block");
                navigationLi.classList.toggle("lg:hidden");

                if (navigationLi.classList.contains("lg:block")) {
                    navigationLi.classList.add("slideDown");
                    navigationLi.classList.remove("slideUp");
                    openSubElements.forEach(function (item) {
                        item.classList.remove("active");
                    });
                    openSubElement.classList.add("active");
                } else {
                    navigationLi.classList.add("slideUp");
                    navigationLi.classList.remove("slideDown");
                    openSubElement.classList.remove("active");
                }
            });
        });
    }

    const searchBtn = document.querySelector('.search-btn');
    const cancel = document.querySelector('.cancel');
    const modalSearch = document.querySelector('.modal-search');

    searchBtn.addEventListener('click', () => {
        modalSearch.classList.remove('hidden');
        modalSearch.classList.add('fadeIn');
    });

    function closeSearchModal() {
        modalSearch.classList.add('fadeOut');
        setTimeout(() => {
            modalSearch.classList.add('hidden');
            modalSearch.classList.remove('fadeOut');
        }, 500);
    }

    cancel.addEventListener('click', closeSearchModal);

    if (document.querySelector(".tabs-links-date")) {
        const tabLinks = document.querySelectorAll('.tabs-links-date a');
        const tabContents = document.querySelectorAll('.content-tab-date .tab-date');

        if (tabLinks.length !== tabContents.length) {
            console.error("La cantidad de enlaces y pestañas no coincide");
            return;
        }

        let activeIndex = 0;

        tabLinks[activeIndex].classList.add('active');
        tabContents[activeIndex].classList.add('active');

        tabLinks.forEach((link, index) => {
            link.addEventListener('click', function (event) {
            event.preventDefault();

            if (index === activeIndex) {
                return;
            }

            tabLinks[activeIndex].classList.remove('active');
            tabContents[activeIndex].classList.remove('active');

            link.classList.add('active');
            tabContents[index].classList.add('active');

            activeIndex = index;
            });
        });
    }

    if (document.querySelector(".link-video")) {
        const linkVideo = document.querySelector('.link-video');
        const modalVideo = document.querySelector('.modal-video');
        const closeModalVideo = document.querySelector('.close-modal-video');

        linkVideo.addEventListener('click', (event) => {
            event.preventDefault();
            modalVideo.classList.remove('hidden');
            modalVideo.classList.add('flex');
        });

        closeModalVideo.addEventListener('click', (event) => {
            event.preventDefault();
            modalVideo.classList.add('hidden');
            modalVideo.classList.remove('flex');
        });
    }

    /*const swiper2 = new Swiper(".grid-slider", {
        modules: [Navigation, Pagination, Autoplay, Grid],
        spaceBetween: 1,
        grid: {
            rows: 2,
        },
        navigation: {
            nextEl: ".swiper-logos-next",
            prevEl: ".swiper-logos-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 2,
            },
            680: {
                slidesPerView: 3,
            },
            1100: {
                slidesPerView: 5,
            }
        }
    });*/

    var gridSliders = document.querySelectorAll('.grid-slider');

    gridSliders.forEach(function(sliderContainer) {
        var gridSlider = new Swiper(sliderContainer, {
            modules: [Navigation, Pagination, Autoplay, Grid],
            spaceBetween: 1,
            grid: {
                rows: 2,
            },
            navigation: {
                nextEl: sliderContainer.parentNode.querySelector('.swiper-logos-next'),
                prevEl: sliderContainer.parentNode.querySelector('.swiper-logos-prev'),
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                },
                680: {
                    slidesPerView: 3,
                },
                1100: {
                    slidesPerView: 5,
                }
            }
        });
    });

    const swiper3 = new Swiper(".slider-blog", {
        modules: [Navigation, Pagination, Autoplay, Grid],
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            0: {
                autoHeight: true,
            },
            680: {
                autoHeight: false,
            },
        }
    });

    /*const swiper = new Swiper('.slider-home', {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        slidesPerView: 2,
        spaceBetween: 58,
        autoplay: {
            delay: 4000,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            0: {
                autoHeight: true,
                slidesPerView: 1,
            },
            680: {
                autoHeight: false,
                slidesPerView: 1.3,
            },
            1100: {
                autoHeight: false,
                slidesPerView: 2,
            }
        }
    });*/

    var homeSliders = document.querySelectorAll('.slider-home');

    homeSliders.forEach(function(sliderContainer2) {
        var homeSlider = new Swiper(sliderContainer2, {
            modules: [Navigation, Pagination, Autoplay],
            loop: true,
            slidesPerView: 2,
            spaceBetween: 58,
            autoplay: {
                delay: 10000,
            },
            pagination: {
                el: sliderContainer2.parentNode.querySelector('.swiper-pagination'),
                clickable: true,
            },
            navigation: {
                nextEl: sliderContainer2.parentNode.querySelector('.swiper-button-next'),
                prevEl: sliderContainer2.parentNode.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                0: {
                    autoHeight: true,
                    slidesPerView: 1,
                },
                680: {
                    autoHeight: false,
                    slidesPerView: 1.3,
                },
                1100: {
                    autoHeight: false,
                    slidesPerView: 2,
                }
            }
        });
    });

    /*const swiper_pay = new Swiper('.slider-pay', {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        slidesPerView: 1,
        spaceBetween: 58,
        autoplay: {
            delay: 4000,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });*/

    var paySliders = document.querySelectorAll('.slider-pay');
    paySliders.forEach(function(sliderContainer3) {
        var paySliders = new Swiper(sliderContainer3, {
            modules: [Navigation, Pagination, Autoplay],
            loop: true,
            slidesPerView: 1,
            spaceBetween: 58,
            autoplay: {
                delay: 4000,
            },
            pagination: {
                el: sliderContainer3.parentNode.querySelector('.swiper-pagination'),
                clickable: true,
            },
        });
    });

    var titles = document.querySelectorAll('.accordion-title');
    var contents = document.querySelectorAll('.accordion-content');
    var icons = document.querySelectorAll('.accordion-icon');

    titles.forEach(function (title, index) {
        title.addEventListener('click', function () {
            var content = contents[index];
            var icon = icons[index];

            if (content.classList.contains('active')) {
                content.classList.remove('active');
                icon.innerHTML = '<i class="lni lni-plus"></i>';
                title.classList.remove('active');
            } else {
                closeAllAccordions();

                content.classList.add('active');
                icon.innerHTML = '<i class="lni lni-close"></i>';
                title.classList.add('active');
            }
        });
    });

    function closeAllAccordions() {
        contents.forEach(function (content) {
            content.classList.remove('active');
        });

        icons.forEach(function (icon) {
            icon.innerHTML = '<i class="lni lni-plus"></i>';
        });

        titles.forEach(function (title) {
            title.classList.remove('active');
        });
    }

    // Form Inputs
    function toggleFilledClass(field) {
        const label = field.closest("label");

        if (!label) return;

        if (field.tagName === 'SELECT') {
            label.classList.toggle("filled", field.selectedIndex !== 0);
        } else {
            label.classList.toggle("filled", field.value.trim() !== '');
        }
    }
    const forms = document.querySelectorAll('.contact-form');
    forms.forEach((form) => {
        const formFields = form.querySelectorAll("input, textarea, select");

        formFields.forEach((field) => {
            toggleFilledClass(field);

            field.addEventListener("input", () => {
                toggleFilledClass(field);
            });

            field.addEventListener("blur", () => {
                toggleFilledClass(field);
            });

            field.addEventListener("focus", () => {
                const label = field.closest("label");
                label.classList.add("filled");
            });
        });
    });

    // Form Inputs
    function toggleFilledClass2(field2) {
        const label2 = field2.closest(".gfield");

        if (!label2) return;

        if (field2.tagName === 'SELECT') {
            label2.classList.toggle("filled", field2.selectedIndex !== 0);
        } else {
            label2.classList.toggle("filled", field2.value.trim() !== '');
        }
    }
    const forms2 = document.querySelectorAll('.gform_wrapper form');
    forms2.forEach((form2) => {
        const formFields2 = form2.querySelectorAll("input, textarea, select");

        formFields2.forEach((field2) => {
            toggleFilledClass2(field2);

            field2.addEventListener("input", () => {
                console.log('input')
                toggleFilledClass2(field2);
            });

            field2.addEventListener("blur", () => {
                console.log('blur')
                toggleFilledClass2(field2);
            });

            field2.addEventListener("focus", () => {
                console.log('focus')
                const label2 = field2.closest(".gfield");
                label2.classList.add("filled");
            });
        });
    });

    if (document.querySelector('.color-box')) {
        const boxes = document.querySelectorAll('.color-box .box');

        boxes.forEach(box => {
            box.addEventListener('mouseenter', () => {
                const color = box.dataset.color;
                box.style.background = color;
            });
            box.addEventListener('mouseleave', () => {
                box.style.background = '';
            });
        });
    }

    if(document.querySelector('.fw-media-container')) {
        const link = document.querySelector('.fw-media-container a');
        const fileName = link.getAttribute('href');

        let regex = /\/([^\/]+)\.pdf$/;
        let title = regex.exec(fileName);
        link.innerHTML = title[1].replace(/-/g, ' ');
    }

    const searchForm = document.getElementById("search-form");
    const searchInput = searchForm.querySelector("input[type='text']");
    const searchResults = document.getElementById("search-results");

    if (!searchForm || !searchInput || !searchResults) {
        return;
    }

    const ajaxUrl = searchForm.dataset.ajaxUrl;
    let debounceTimer;

    searchInput.addEventListener("input", function () {
        clearTimeout(debounceTimer);

        const query = searchInput.value;

        searchResults.style.display = "none";

        if (query.length > 2) {
            debounceTimer = setTimeout(() => {
                fetch(ajaxUrl + "?action=search_ajax&query=" + encodeURIComponent(query))
                    .then(response => {
                        if (response.ok) {
                            return response.text();
                        } else {
                            throw new Error('Error en la petición AJAX');
                        }
                    })
                    .then(text => {
                        searchResults.innerHTML = text;
                        searchResults.style.display = "block";
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }, 300); // 300 ms de espera
        } else {
            searchResults.innerHTML = "";
        }
    });

    if (document.querySelector('.number-counter')) {
        const numberCounter = document.querySelector('.number-counter');

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: .5,
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const elements = document.querySelectorAll('.number-counter-item');

                    elements.forEach((element, index) => {
                        const number = element.querySelector('.number');
                        const text = element.querySelector('.text');
                        const timer = +number.getAttribute('data-counter');
                        const timeInSeconds = +number.getAttribute('data-time');
                        const increment = timer / (60 * timeInSeconds);

                        let currentData = 0;

                        const formatNumber = (number) => {
                            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        };

                        const animateNumber = () => {
                            if (currentData < timer) {
                                currentData += increment;
                                if (currentData > timer) {
                                    currentData = timer;
                                }
                                const formattedNumber = formatNumber(Math.ceil(currentData));
                                number.innerText = formattedNumber;
                                requestAnimationFrame(animateNumber);
                            } else {
                                animateText();
                            }
                        };

                        const animateText = () => {
                            text.classList.add('animate-in');
                        };

                        const delay = 800 * index;

                        setTimeout(() => {
                            animateNumber();
                        }, delay);
                    });

                    observer.unobserve(entry.target);
                }
            });
        }, options);

        observer.observe(numberCounter);
    }

    if(document.querySelector('.background-slider')) {
        const wrapper = document.querySelector('.background-slider');

        const bgSlider = new Swiper('.bg-slider', {
            modules: [Pagination, Autoplay, EffectFade],
            loop: true,
            slidesPerView: 1,
            effect: 'fade',
            autoplay: {
                delay: 5000,
            },
            pagination: {
                el: '.background-slider-pagination',
                clickable: true,
            },
            on: {
                slideChange: function () {
                    const activeSlide = this.slides[this.activeIndex];
                    const color = activeSlide.dataset.color;
                    wrapper.style.backgroundColor = color;
                },
                init: function () {
                    const activeSlide = this.slides[this.activeIndex];
                    const color = activeSlide.dataset.color;
                    wrapper.style.backgroundColor = color;
                },
            },
        });
    }

    //animation text


    const textElements = document.querySelectorAll('.text-show');

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
    };

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            observer.unobserve(entry.target);
        }
        });
    }, options);

    textElements.forEach(element => {
        observer.observe(element);
    });

    //animation image left and right

    const imageLeftElements = document.querySelectorAll('.left-image');
    const imageRightElements = document.querySelectorAll('.right-image');
    let previousScrollY = 0;

    function updateParallax() {
        const currentScrollY = window.scrollY;
        const scrollDirection = currentScrollY - previousScrollY;

        imageLeftElements.forEach(element => {
        const rate = scrollDirection > 0 ? Math.min(0, currentScrollY / 2) : Math.max(-50, -currentScrollY);

        if (element.classList.contains('in-viewport')) {
            if (currentScrollY === 0) {
            element.style.transform = `translateX(-50px)`;
            } else {
            element.style.transform = `translateX(${rate}px)`;
            }
        }
        });

        imageRightElements.forEach(element => {
        const rate = scrollDirection > 0 ? Math.min(currentScrollY, 0) : Math.max(-currentScrollY, 50);

        if (element.classList.contains('in-viewport')) {
            if (currentScrollY === 0) {
            element.style.transform = `translateX(50px)`;
            } else {
            element.style.transform = `translateX(${rate}px)`;
            }
        }
        });

        previousScrollY = currentScrollY;
    }

    const observerImages = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.classList.add('in-viewport');
        } else {
            entry.target.classList.remove('in-viewport');
        }
        });
    }, {
        threshold: 0.5
    });

    imageLeftElements.forEach(element => {
        observerImages.observe(element);
    });

    imageRightElements.forEach(element => {
        observerImages.observe(element);
    });

    window.addEventListener('scroll', updateParallax);
});